// IMPORT REACT
import React from 'react'
// IMPORT LINK
import { Link } from 'react-router-dom'
// IMPORT NAVBAR BOTTOM
import NavbarBot from '../../Layout/NavbarBot.jsx'
// WE PERNIKAHAN
const WePernikahan = () => { // KURUNG BUKA WE PERNIKAHAN
    // PROSES LOOP JENIS WE PERNIKAHAN
    const jenisWePernikahan = [
        { viewLink: '/we-pernikahan/aglaonema', nama: 'Aglaonema', imgSrc: 'img/undanganDigital/UndanganDigitalPernikahan/aglaonema.png', alt: 'Undangan Digital Pernikahan Aglaonema' },
        { viewLink: '/we-pernikahan/agapanthus', nama: 'Agapanthus', imgSrc: 'img/undanganDigital/UndanganDigitalPernikahan/agapanthus.png', alt: 'Undangan Digital Pernikahan Agapanthus' },
        { viewLink: '/we-pernikahan/alamanda', nama: 'Alamanda', imgSrc: 'img/undanganDigital/UndanganDigitalPernikahan/alamanda.png', alt: 'Undangan Digital Pernikahan Alamanda' },
        { viewLink: '/we-pernikahan/amaryllis', nama: 'Amaryllis', imgSrc: 'img/undanganDigital/UndanganDigitalPernikahan/amaryllis.png', alt: 'Undangan Digital Pernikahan Amaryllis' },
        { viewLink: '/we-pernikahan/bnw1', nama: 'Bnw 1', imgSrc: 'img/undanganDigital/UndanganDigitalPernikahan/bnw1.png', alt: 'Undangan Digital Pernikahan Bnw 1' },
        { viewLink: '/we-pernikahan/bnw2', nama: 'Bnw 2', imgSrc: 'img/undanganDigital/UndanganDigitalPernikahan/bnw2.png', alt: 'Undangan Digital Pernikahan Bnw 2' },
        { viewLink: '/we-pernikahan/bnw3', nama: 'Bnw 3', imgSrc: 'img/undanganDigital/UndanganDigitalPernikahan/bnw3.png', alt: 'Undangan Digital Pernikahan Bnw 3' },
        { viewLink: '/we-pernikahan/bnw4', nama: 'Bnw 4', imgSrc: 'img/undanganDigital/UndanganDigitalPernikahan/bnw4.png', alt: 'Undangan Digital Pernikahan Bnw 4' },
        { viewLink: '/we-pernikahan/fotografi', nama: 'Fotografi', imgSrc: 'img/undanganDigital/UndanganDigitalPernikahan/fotografi.png', alt: 'Undangan Digital Pernikahan Fotografi' },
        { viewLink: '/we-pernikahan/bike', nama: 'Bike', imgSrc: 'img/undanganDigital/UndanganDigitalPernikahan/bike.png', alt: 'Undangan Digital Pernikahan Bike' },
        { viewLink: '/we-pernikahan/football', nama: 'Football', imgSrc: 'img/undanganDigital/UndanganDigitalPernikahan/football.png', alt: 'Undangan Digital Pernikahan Football' },
        { viewLink: '/we-pernikahan/badminton', nama: 'Badminton', imgSrc: 'img/undanganDigital/UndanganDigitalPernikahan/badminton.png', alt: 'Undangan Digital Pernikahan Badminton' },
        { viewLink: '/we-pernikahan/blegold', name: 'BleGold', imgSrc: 'img/undanganDigital/UndanganDigitalPernikahanblegold.png', alt: 'Undangan Digital Pernikahan' },
        { viewLink: '/we-pernikahan/blepink', name: 'BlePink', imgSrc: 'img/undanganDigital/UndanganDigitalPernikahanblepink.png', alt: 'Undangan Digital Pernikahan' },
        { viewLink: '/we-pernikahan/bletosca', name: 'BleTosca', imgSrc: 'img/undanganDigital/UndanganDigitalPernikahanbletosca.png', alt: 'Undangan Digital Pernikahan' },
        { viewLink: '/we-pernikahan/blewhite', name: 'BleWhite', imgSrc: 'img/undanganDigital/UndanganDigitalPernikahanblewhite.png', alt: 'Undangan Digital Pernikahan' },
        { viewLink: '/we-pernikahan/cute', name: 'Cute', imgSrc: 'img/undanganDigital/UndanganDigitalPernikahancute.png', alt: 'Undangan Digital Pernikahan' },
        { viewLink: '/we-pernikahan/adorable', name: 'Adorable', imgSrc: 'img/undanganDigital/UndanganDigitalPernikahanadorable.png', alt: 'Undangan Digital Pernikahan' },
        { viewLink: '/we-pernikahan/beauty', name: 'Beauty', imgSrc: 'img/undanganDigital/UndanganDigitalPernikahanbeauty.png', alt: 'Undangan Digital Pernikahan' },
        { viewLink: '/we-pernikahan/cool', name: 'Cool', imgSrc: 'img/undanganDigital/UndanganDigitalPernikahancool.png', alt: 'Undangan Digital Pernikahan' }
    ]
    // RETURN
    return ( // KURUNG BUKA RETURN
        <div className='background-home' style={{marginTop:'55px'}}>
            {/* INFO */}
                <section id='home'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12 col-lg-6 px-3'>
                                <h2 className='title-webew-home'>UD Pernikahan</h2>
                                <p className='text-webew-home'>Bikin undangan digital disini, tinggal pilih tema undangan digital, isi form data dengan benar, dan undangan digital sudah jadi</p>
                                <div className='row'>
                                    <div className='col-6'>
                                        <a href='/' className='btn btn-webew-home' aria-label='Kembali webew.id'>Kembali</a>
                                    </div>
                                    <div className='col-6'>
                                        <a href='#undanganDigital' className='btn btn-webew-home' aria-label='Produk webew.id'>Desain</a>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-lg-6'>
                                <img src='https://webew.id/img/19212427_Business people arranging appointment in digital booking app.webp' className='img-webew-home mx-auto d-block mt-4' style={{width: '100%',minHeight:'200px'}} alt='by Freepik on freepik'/>
                            </div>
                        </div>
                    </div>    
                </section>
            {/* TUTUP INFO */}
            <img src='https://webew.id/img/bg-lengkung.png' className='img-bg-lengkung'  style={{width: '100%',minHeight:'360px'}} alt='bg lengkung'/>
            {/* JENIS WE PERNIKAHAN */}
                <section id='jenisWePernikahan' style={{ marginTop: '-70px' }}>
                    <div className='container'>
                        <h6 className='fw-bold text-center card-text-rupiah'>
                            Note: Tidak ada masa aktif ya, kamu bisa membuka kapan saja jika mau
                        </h6>
                        <div className='row'>
                            {jenisWePernikahan.map((data, index) => (
                                <div className='col-6 col-md-4 col-lg-3' key={index.id} id='undanganDigital'>
                                    <div className='card' style={{ width: '100%', minHeight: '390px' }}>
                                        <h5 className='card-title text-center mt-2'>{data.nama}</h5>
                                        <img src={data.imgSrc} className='mx-auto d-block mt-1' style={{ width: '125px', minHeight: '175px' }} alt={data.alt} />
                                        <div className='card-body'>
                                            <p className='card-text-harga'>Harga Saat Ini</p>
                                            <p className='card-text-rupiah'>Rp 45.000</p>
                                            <div className='row'>
                                                <div className='col-12 col-sm-6'>
                                                    <Link to={data.viewLink} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</Link>
                                                </div>
                                                <div className='col-12 col-sm-6'>
                                                    <Link to={'https://webew.id/login'} className='btn btn-webew-product mt-1 mb-1' aria-label='Produk webew.id'>Bikin</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            {/* TUTUP JENIS WE PERNIKAHAN */}
            <img src='https://webew.id/img/bg-lengkung.png' className='img-bg-lengkung-bawah'  style={{width: '100%',minHeight:'360px'}} alt='bg lengkung'/>
            {/* CREDIT IMAGE */}
                <section id='credit-image'>
                    <div className='container mb-4'>
                        <h3 className='judul-credit text-center'>Image By</h3>
                        <div className='row justify-content-center'>
                            <div className='col-8 col-sm-6 col-md-4 col-lg-3 mb-3'>
                                <a href='https://www.freepik.com/free-vector/business-people-arranging-appointment-digital-booking-app_19212427.htm#page=4&query=app&position=22&from_view=author' aria-label='Link mengarah ke pembuat image yaitu Pch-vector Freepik'>
                                    <div className='card' style={{width: '100%',minHeight:'150px'}}>
                                        <img src='https://webew.id/img/credit/19212427_Business people arranging appointment in digital booking app.webp' className='mx-auto d-block'  style={{width: '100%',minHeight:'120px'}} alt='by Pch-vector on Freepik'/>
                                        <div className='card-body'>
                                            <p className='text-center'>Pch-vector on Freepik</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
            {/* TUTUP CREDIT IMAGE */}
            {/* NAVBAR BOTTOM */}
                <NavbarBot/>
            {/* TUTUP NAVBAR BOTTOM */}
        </div>
    ) // KURUNG TUTUP RETURN
} // KURUNG TUTUP WE PERNIKAHAN
// EKSPOR
export default WePernikahan
// IMPORT REACT
import React from 'react'
// IMPORT LINK
import { Link } from 'react-router-dom'
// FOOTER
const Footer = () => {
    // RETURN
    return (
        // FOOTER
        <footer>
            <div className='container mb-4'>
                <p className='copyright text-center fw-semibold' style={{color:'#d1d1d1',fontSize:'9pt'}}>&copy; 2024 webew.id. All Rights Reserved<Link href='https://www.privacypolicyonline.com/live.php?token=HjdimFn29DuNIXNZzYRRZgjPZVt6jarW' className='fw-bold' style={{color:'#d1d1d1',fontSize:'9pt'}}>. Privacy Policy</Link></p>
            </div>
        </footer>
    )
}
// EKSPOR
export default Footer
// IMPORT REACT
import React,{ useState, useEffect } from 'react'
// IMPORT AXIOS
import axios from "axios"
// IMPORT JWT DECODE
import jwt_decode from "jwt-decode"
// IMPORT USE NAVIGATE, LINK
import { useNavigate, Link } from "react-router-dom"
// IMPORT MENU SIDEBAR
import MenuSidebar from '../Layout/MenuSideBar.jsx'
// IMPORT MENU NAVBAR
import MenuNavbar from '../Layout/MenuNavbar.jsx'
// IMPORT MENU NAVBAR BOTTOM
import MenuNavbarBot from '../Layout/MenuNavbarBot.jsx'
// IMPORT MENU DONASI
import MenuDonasi from '../Layout/MenuDonasi.jsx'
// MENU WE PERNIKAHAN
const MenuWePernikahan = () => { // KURUNG BUKA MENU WE PERNIKAHAN
    // MEMBUAT VARIABEL WE PERNIKAHAN
    const [displayLoader, setDisplayLoader] = useState("")
    const [panggilan, setPanggilan] = useState('')
    // MEMBUAT VARIABEL EXPIRE TOKEN
    const [expire, setExpire] = useState('')
    // MEMBUAT VARIABEL TOKEN
    const [, setToken] = useState('')
    // MEMBUAT VARIABEL NAVIGATE BY USE NAVIGATE
    const navigate = useNavigate()
    // USEEFFECT
    useEffect(()=>{
        refreshToken()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // REFRESH TOKEN
    const refreshToken = async() => {
        try {
            const response = await axios.get('https://server13.webew.id/tokenBE')
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setPanggilan(decoded.panggilan)
            setExpire(decoded.exp)
            setDisplayLoader("none")
        } catch (error) {
            if(error.response){
                navigate("/login", { replace: true })
            }   
        }
    }
    const axiosJWT = axios.create()
    axiosJWT.interceptors.request.use(async(config) => {
        const currentDate = new Date()
        if(expire * 1000 < currentDate.getTime()){
            const response = await axios.get('https://server13.webew.id/tokenBE')
            config.headers.Authorization = `Bearer ${response.data.accessToken}`
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setPanggilan(decoded.panggilan)
            setExpire(decoded.exp)
        }
        return config
    }, (error)=>{
        return Promise.reject(error)
    })
    // PROSES LOOP JENIS WE PERNIKAHAN
    const jenisWePernikahan = [
        { createLink: `/menu-we-pernikahan-form/${panggilan}/aglaonema`, viewLink: '/we-pernikahan/aglaonema', nama: 'Aglaonema', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/aglaonema.png', alt: 'Undangan Digital Pernikahan Aglaonema' },
        { createLink: `/menu-we-pernikahan-form/${panggilan}/agapanthus`, viewLink: '/we-pernikahan/agapanthus', nama: 'Agapanthus', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/agapanthus.png', alt: 'Undangan Digital Pernikahan Agapanthus' },
        { createLink: `/menu-we-pernikahan-form/${panggilan}/alamanda`, viewLink: '/we-pernikahan/alamanda', nama: 'Alamanda', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/alamanda.png', alt: 'Undangan Digital Pernikahan Alamanda' },
        { createLink: `/menu-we-pernikahan-form/${panggilan}/amaryllis`, viewLink: '/we-pernikahan/amaryllis', nama: 'Amaryllis', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/amaryllis.png', alt: 'Undangan Digital Pernikahan Amaryllis' },
        { createLink: `/menu-we-pernikahan-form/${panggilan}/bnw1`, viewLink: '/we-pernikahan/bnw1', nama: 'Bnw 1', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bnw1.png', alt: 'Undangan Digital Pernikahan Bnw 1' },
        { createLink: `/menu-we-pernikahan-form/${panggilan}/bnw2`, viewLink: '/we-pernikahan/bnw2', nama: 'Bnw 2', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bnw2.png', alt: 'Undangan Digital Pernikahan Bnw 2' },
        { createLink: `/menu-we-pernikahan-form/${panggilan}/bnw3`, viewLink: '/we-pernikahan/bnw3', nama: 'Bnw 3', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bnw3.png', alt: 'Undangan Digital Pernikahan Bnw 3' },
        { createLink: `/menu-we-pernikahan-form/${panggilan}/bnw4`, viewLink: '/we-pernikahan/bnw4', nama: 'Bnw 4', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bnw4.png', alt: 'Undangan Digital Pernikahan Bnw 4' },
        { createLink: `/menu-we-pernikahan-form/${panggilan}/fotografi`, viewLink: '/we-pernikahan/fotografi', nama: 'Fotografi', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/fotografi.png', alt: 'Undangan Digital Pernikahan Fotografi' },
        { createLink: `/menu-we-pernikahan-form/${panggilan}/bike`, viewLink: '/we-pernikahan/bike', nama: 'Bike', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bike.png', alt: 'Undangan Digital Pernikahan Bike' },
        { createLink: `/menu-we-pernikahan-form/${panggilan}/football`, viewLink: '/we-pernikahan/football', nama: 'Football', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/football.png', alt: 'Undangan Digital Pernikahan Football' },
        { createLink: `/menu-we-pernikahan-form/${panggilan}/badminton`, viewLink: '/we-pernikahan/badminton', nama: 'Badminton', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/badminton.png', alt: 'Undangan Digital Pernikahan Badminton' },
        { createLink: `/menu-we-pernikahan-form/${panggilan}/blegold`, viewLink: '/we-pernikahan/blegold', name: 'BleGold', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahanblegold.png', alt: 'Undangan Digital Pernikahan' },
        { createLink: `/menu-we-pernikahan-form/${panggilan}/blepink`, viewLink: '/we-pernikahan/blepink', name: 'BlePink', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahanblepink.png', alt: 'Undangan Digital Pernikahan' },
        { createLink: `/menu-we-pernikahan-form/${panggilan}/bletosca`, viewLink: '/we-pernikahan/bletosca', name: 'BleTosca', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahanbletosca.png', alt: 'Undangan Digital Pernikahan' },
        { createLink: `/menu-we-pernikahan-form/${panggilan}/blewhite`, viewLink: '/we-pernikahan/blewhite', name: 'BleWhite', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahanblewhite.png', alt: 'Undangan Digital Pernikahan' },
        { createLink: `/menu-we-pernikahan-form/${panggilan}/cute`, viewLink: '/we-pernikahan/cute', name: 'Cute', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahancute.png', alt: 'Undangan Digital Pernikahan' },
        { createLink: `/menu-we-pernikahan-form/${panggilan}/adorable`, viewLink: '/we-pernikahan/adorable', name: 'Adorable', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahanadorable.png', alt: 'Undangan Digital Pernikahan' },
        { createLink: `/menu-we-pernikahan-form/${panggilan}/beauty`, viewLink: '/we-pernikahan/beauty', name: 'Beauty', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahanbeauty.png', alt: 'Undangan Digital Pernikahan' },
        { createLink: `/menu-we-pernikahan-form/${panggilan}/cool`, viewLink: '/we-pernikahan/cool', name: 'Cool', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahancool.png', alt: 'Undangan Digital Pernikahan' }
    ]
    // RETURN
    return ( // KURUNG BUKA RETURN
        <div id="wrapper">
            {/* LOADER */}
                <div className="loader-page-bg" style={{display:displayLoader}}>
                    <div className="loader-page"></div>
                </div>
            {/* TUTUP LOADER */}
            {/* MODAL DONASI */}
                <MenuDonasi/>
            {/* TUTUP MODAL DONASI */}
            {/* SIDEBAR */}
                <MenuSidebar/>
            {/* TUTUP SIDEBAR */}
            {/* KONTEN WRAPPER */}
                <div id="content-wrapper">
                    {/* NAVBAR */}
                        <MenuNavbar/>
                    {/* TUTUP NAVBAR */}
                    {/* KONTEN */}
                        <div className="container-fluid" style={{overflow:"auto",display:"block",width:"100%",height:"88vh"}}>
                            <h4 className="text-center mt-3" style={{fontWeight:"700"}}>We Pernikahan</h4>
                            <section className=' mb-5 pb-5' style={{marginTop:"20px"}}>
                                <h6 className='fw-bold text-center card-text-rupiah'>Note : Tidak ada masa aktif ya, jadi bisa lihat kapan saja</h6>
                                <div className="row mb-5" style={{marginTop:"-40px"}}>
                                   {jenisWePernikahan.map((data, index) => (
                                        <div key={index.id} className='col-6 col-lg-4 col-xl-3' id='undanganDigital'>
                                            <div className='card' style={{ width: '100%', minHeight: '390px' }}>
                                                <h5 className='card-title text-center mt-2'>{data.nama}</h5>
                                                <img src={data.imgSrc} className='mx-auto d-block mt-1' style={{ width: '125px', minHeight: '175px' }} alt={data.alt} />
                                                <div className='card-body'>
                                                    <p className='card-text-harga'>Harga Saat Ini</p>
                                                    <p className='card-text-rupiah'>Rp 45.000</p>
                                                    <div className='row'>
                                                        <div className='col-12 col-sm-6'>
                                                            <Link to={`${data.viewLink}`} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</Link>
                                                        </div>
                                                        <div className='col-12 col-sm-6'>
                                                            <Link to={`${data.createLink}`} className='btn btn-webew-product mt-1 mb-1' aria-label='Produk webew.id'>Bikin</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </section>
                            {/* NAVBOT */}
                            <MenuNavbarBot/>
                        </div>
                    {/* TUTUP KONTEN */}
                </div>
            {/* TUTUP KONTEN WRAPPER */}
        </div>
    ) // TUTUP KURUNG RETURN
} // TUTUP KURUNG MENU WE PERNIKAHAN
// EKSPOR
export default MenuWePernikahan